import React, { useState } from "react";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const navItems = [
    { text: "Home", onClick: () => console.log("Home clicked") },
    {
      text: "ROI Calculator",
      onClick: () => console.log("ROI Calculator clicked"),
    },
    {
      text: "Supported By",
      onClick: () => console.log("Supported By clicked"),
    },
    { text: "Contact", onClick: () => console.log("Contact clicked") },
    {
      text: "Funded",
      onClick: () => console.log("Funded clicked"),
      bgcolor: "yellow",
    },
    {
      text: "Book a Demo",
      onClick: () => console.log("Book a Demo clicked"),
      bgcolor: "#FFF0FB",
      color: "#AD2ED4",
    },
  ];

  return (
    <AppBar
      position="sticky"
      sx={{ bgcolor: "white", boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 4px" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            src="https://i.postimg.cc/mkGWG9fr/Whats-App-Image-2024-09-27-at-16-28-01-1-removebg-preview.jpg"
            alt="LiaPlus AI"
            sx={{ height: 40, mr: 2 }}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {navItems.map((item, index) => (
            <Button
              key={index}
              sx={{
                mx: 1,
                bgcolor: item.bgcolor || "transparent",
                color: item.color || "black",
                "&:hover": { bgcolor: item.bgcolor || "transparent" },
              }}
              onClick={item.onClick}
            >
              {item.text}
            </Button>
          ))}
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {navItems.map((item, index) => (
              <ListItem button key={index} onClick={item.onClick}>
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: item.color || "black",
                    bgcolor: item.bgcolor || "transparent",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;