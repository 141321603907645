import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from '../pages/Home';
import Report from '../pages/Report';
const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/report" element={<Report />} />
      
    </Routes>
  );
}

export default AllRoutes