import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "60vh",
  padding: "20px",
}));

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  maxWidth: '1000px',
  flexDirection: 'row', // Horizontal layout by default
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', // Stack vertically on small screens
    alignItems: 'center',
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  height: '400px',
  width: '300px',
  backgroundImage: 'url("https://i.postimg.cc/1XrWDS5G/Whats-App-Image-2024-09-27-at-21-39-47.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '20px',
  zIndex: 2,
  [theme.breakpoints.down('sm')]: {
    position: 'relative', // Move the image inside the column layout on smaller screens
    height: '450px', // Smaller height for mobile
    width: '75%', // Adjust width to fit smaller screens
    marginBottom: '20px', // Add spacing below image on mobile
    transform: 'none', // Remove translation on mobile
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  marginLeft: "280px", // Overlap space for larger screens
  width: "calc(100% - 280px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0", // Reset margin for small screens
    width: "100%",
    alignItems: "center", // Center content for mobile
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  margin: '15px',
  justifyContent: "space-between",
  padding: "12px",
  backgroundColor: "#F9ECF9",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  width: "95%", // Ensure the box takes full width on mobile
  [theme.breakpoints.down("sm")]: {
    padding: "10px", // Smaller padding for mobile
    width: "90%", // Narrower width for better readability
  },
}));

const StatTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 700,
  fontSize: '24px',
  color: '#AE0EB9',
  marginBottom: '5px',
  marginLeft: '25px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px', // Smaller font size for mobile
    marginLeft: '0', // Centered text
    textAlign: 'center',
  },
}));

const StatSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '16px',
  color: '#000000',
  marginLeft: '25px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', // Smaller font size for mobile
    marginLeft: '0', // Centered text
    textAlign: 'center',
  },
}));

const Metrics = () => {
  return (
    <Root>
      <Container>
        <ImageContainer />
        <ContentContainer>
          <StatBox>
            <StatTitle>CSAT Improvement: +30%</StatTitle>
            <StatSubtitle>
              Within the first two months of the partnership, it will lead to
              increased retention and loyalty.
            </StatSubtitle>
          </StatBox>
          <StatBox>
            <StatTitle>
              Shorter Average Handling Time (AHT): 15+ minutes
            </StatTitle>
            <StatSubtitle>
              Reduced time spent on each customer interaction
            </StatSubtitle>
          </StatBox>
          <StatBox>
            <StatTitle>Faster Response Time: 99.5%</StatTitle>
            <StatSubtitle>
              Near-perfect rate of timely responses to customer inquiries
            </StatSubtitle>
          </StatBox>
        </ContentContainer>
      </Container>
    </Root>
  );
};

export default Metrics;