// import React, { useState } from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// const DownLoadPdfButton = () => {
//   const [isGenerating, setIsGenerating] = useState(false);

//   const handleDownload = async () => {
//     setIsGenerating(true);
//     const element = document.getElementById("report");

//     // Calculate total height for a single page based on the element's content
//     const canvas = await html2canvas(element, { scale: 2, logging: true });
//     const imgData = canvas.toDataURL("image/jpeg", 0.8); // Compressing image to reduce size

//     const imgWidth = 210; // A4 page width in mm
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;

//     // Creating PDF and adding content
//     const pdf = new jsPDF("p", "mm", [imgWidth, imgHeight]);
//     pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

//     // Make any links in the document clickable
//     const links = element.querySelectorAll("a");
//     links.forEach((link) => {
//       const { x, y, width, height } = link.getBoundingClientRect();
//       const linkUrl = link.href;
//       const mmX = (x / canvas.width) * imgWidth;
//       const mmY = (y / canvas.height) * imgHeight;
//       const mmWidth = (width / canvas.width) * imgWidth;
//       const mmHeight = (height / canvas.height) * imgHeight;

//       pdf.link(mmX, mmY, mmWidth, mmHeight, { url: linkUrl });
//     });

//     // Check the size of the PDF and ensure it is under 5MB
//     const pdfDataUri = pdf.output("datauristring");
//     const base64Data = pdfDataUri.split(",")[1];
//     const binaryData = atob(base64Data);
//     const byteLength = binaryData.length;

//     // Convert size to MB and check if it's greater than 5MB
//     const sizeInMB = byteLength / (1024 * 1024);
//     if (sizeInMB > 5) {
//       alert("PDF size exceeds 5MB. Try reducing content or compression.");
//       setIsGenerating(false);
//       return;
//     }

//     pdf.save("ROI Report.pdf");
//     setIsGenerating(false);
//   };

//   return (
//     <button onClick={handleDownload} disabled={isGenerating}>
//       {isGenerating ? "Generating PDF..." : "Download as PDF"}
//     </button>
//   );
// };

// export default DownLoadPdfButton;

import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DownLoadPdfButton = () => {
  const [isGenerating, setIsGenerating] = useState(false);

  const handleDownload = async () => {
    setIsGenerating(true);
    const element = document.getElementById("report");

    // Ensure images are fully loaded using `onclone`
    const canvas = await html2canvas(element, {
      scale: 2,
      logging: true,
      useCORS: true, // Enable cross-origin resource sharing if images are from another domain
      onclone: (documentClone) => {
        // Force images to load by triggering the load event
        const imgs = documentClone.querySelectorAll("img");
        imgs.forEach((img) => {
          if (!img.complete) {
            img.onload = () => console.log(`${img.src} loaded`);
            img.onerror = () => console.error(`${img.src} failed to load`);
          }
        });
      },
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.8); // Compressing image to reduce size
    const imgWidth = 210; // A4 page width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Creating PDF and adding content
    const pdf = new jsPDF("p", "mm", [imgWidth, imgHeight]);
    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

    // Make any links in the document clickable
    const links = element.querySelectorAll("a");
    links.forEach((link) => {
      const { x, y, width, height } = link.getBoundingClientRect();
      const linkUrl = link.href;
      const mmX = (x / canvas.width) * imgWidth;
      const mmY = (y / canvas.height) * imgHeight;
      const mmWidth = (width / canvas.width) * imgWidth;
      const mmHeight = (height / canvas.height) * imgHeight;

      pdf.link(mmX, mmY, mmWidth, mmHeight, { url: linkUrl });
    });

    // Check the size of the PDF and ensure it is under 5MB
    const pdfDataUri = pdf.output("datauristring");
    const base64Data = pdfDataUri.split(",")[1];
    const binaryData = atob(base64Data);
    const byteLength = binaryData.length;

    // Convert size to MB and check if it's greater than 5MB
    const sizeInMB = byteLength / (1024 * 1024);
    if (sizeInMB > 5) {
      alert("PDF size exceeds 5MB. Try reducing content or compression.");
      setIsGenerating(false);
      return;
    }

    pdf.save("ROI Report.pdf");
    setIsGenerating(false);
  };

  return (
    <button
      onClick={handleDownload}
      disabled={isGenerating}
      style={{
        position: "fixed",
        top: "20px",
        right: "20px",
        zIndex: 1000,
        border: "2px solid purple",
        backgroundColor: "transparent",
        color: "purple",
        padding: "10px 20px",
        borderRadius: "8px",
        fontWeight: "bold",
        fontSize: "14px",
        cursor: "pointer",
        transition: "background-color 0.3s, color 0.3s",
      }}
      onMouseOver={(e) => {
        e.target.style.backgroundColor = "purple";
        e.target.style.color = "white";
      }}
      onMouseOut={(e) => {
        e.target.style.backgroundColor = "transparent";
        e.target.style.color = "purple";
      }}
    >
      {isGenerating ? "Generating PDF..." : "Download PDF"}
    </button>
  );
};

export default DownLoadPdfButton;
