import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const InputField = ({
  label,
  value,
  setValue,
  incrementValue,
  decrementValue,
  suffix = "",
}) => {
  const [intervalId, setIntervalId] = useState(null);

  const handleIncrement = () => setValue((prev) => prev + incrementValue);
  const handleDecrement = () =>
    setValue((prev) => Math.max(0, prev - decrementValue));

  const handleChange = (event) => {
    const newValue =
      event.target.value === "" ? "" : Number(event.target.value);
    setValue(newValue);
  };

  const startIncrementing = () => {
    handleIncrement()
    if (!intervalId) {
      const id = setInterval(handleIncrement, 200);
      setIntervalId(id);
    }
  };

  const startDecrementing = () => {
    handleDecrement()
    if (!intervalId) {
      const id = setInterval(handleDecrement, 200);
      setIntervalId(id);
    }
  };

  const stopIncrementingDecrementing = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => {
    return () => stopIncrementingDecrementing(); // Cleanup on unmount
  }, [intervalId]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Typography
        variant="body1"
        sx={{ flexGrow: 1, color: "#666", fontSize: "0.9rem" }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "white",
          borderRadius: "20px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Button
          onMouseDown={startDecrementing}
          onMouseUp={stopIncrementingDecrementing}
          onMouseLeave={stopIncrementingDecrementing}
          sx={{
            minWidth: "32px",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            color: "#AD2ED4",
            "&:hover": { bgcolor: "transparent" },
          }}
        >
          <RemoveIcon fontSize="small" />
        </Button>
        <TextField
          value={value}
          onChange={handleChange}
          variant="standard"
          sx={{
            width: "80px",
            input: {
              textAlign: "center",
              fontSize: "0.9rem",
              padding: "0",
              color: "gray",
              paddingRight: suffix ? "0" : "10px", // Adjust padding based on suffix
            },
          }}
          InputProps={{
            endAdornment: suffix && (
              <InputAdornment position="end">{suffix}</InputAdornment>
            ),
            disableUnderline: true,
          }}
        />

        <Button
          onMouseDown={startIncrementing}
          onMouseUp={stopIncrementingDecrementing}
          onMouseLeave={stopIncrementingDecrementing}
          sx={{
            minWidth: "32px",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            color: "#AD2ED4",
            "&:hover": { bgcolor: "transparent" },
          }}
        >
          <AddIcon fontSize="small" />
        </Button>
      </Box>
    </Box>
  );
};

export default InputField;