import React from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomScrollbarSelect = ({ value, onChange, options, placeholder }) => {
  return (
    <FormControl
      sx={{
        width: "50%",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "rgb(178,15,184)", // Change border color to red on focus
          },
      }}
    >
      <Select
        required
        sx={{
          height: "50px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          "&:hover": { borderColor: "#888" },
          "& .MuiSelect-select": {
            paddingRight: "32px",
          },
        }}
        value={value}
        onChange={onChange}
        displayEmpty
        renderValue={(selected) => {
          return selected ? (
            selected
          ) : (
            <span style={{ fontSize: "0.9rem", color: "gray" }}>
              {placeholder}
            </span>
          );
        }}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: "200px",
              overflowY: "auto",
              borderRadius: "4px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
              zIndex: 1300,
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
                "&:hover": {
                  background: "#555",
                },
              },
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              "&.Mui-selected": {
                backgroundColor: "#e0e0e0",
                "&:hover": {
                  backgroundColor: "#d0d0d0",
                },
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomScrollbarSelect;
