import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const RoadmapContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  maxWidth: "1000px",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
}));

const TimelineItem = styled(Box)(({ isLeft, theme }) => ({
  display: "flex",
  justifyContent: isLeft ? "flex-start" : "flex-end",
  alignItems: "center",
  width: "100%",
  marginBottom: "50px",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const TimelineLine = styled(Box)(() => ({
  width: "2px",
  backgroundColor: "#AE0EB9",
  height: "calc(90% + 80px)",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  top: "-20px",
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    left: "-4px",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#AE0EB9",
  },
  "&::before": { top: 0 },
  "&::after": { bottom: 0 },
}));

const TimeLabel = styled(Paper)(({ theme }) => ({
  padding: "5px 15px",
  borderRadius: "20px",
  backgroundColor: "#fbd38d",
  display: "inline-block",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "5px",
  },
}));

const StyledCard = styled(Box)(({ isLeft, theme }) => ({
  maxWidth: "90%", // Adjust for smaller screens
  marginLeft: isLeft ? "0" : "auto",
  marginRight: isLeft ? "auto" : "0",
  position: "relative",
  backgroundColor: "#F9ECF9",
  borderRadius: "8px",
  padding: "20px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "50%",
  },
}));

const IconWrapper = styled(Box)(() => ({
  backgroundColor: "#AE0EB9",
  borderRadius: "50%",
  padding: "5px",
  display: "inline-flex",
  marginRight: "8px",
}));

const Roadmap = () => {
  const steps = [
    {
      time: "1 Day",
      title: "Connect with LiaPlus AI's experts",
      description:
        "A highly personalized session with an AI expert to understand your requirements, inputs and implementation details.",
      icon: <StarIcon sx={{ color: "white" }} />,
    },
    {
      time: "1 Week",
      title: "Planning for automation",
      description:
        "Depending on your requirements and goals, LiaPlus AI's experts will lay a solid plan to implement customer support automation for your business.",
      icon: <CheckCircleIcon sx={{ color: "white" }} />,
    },
    {
      time: "1 Month",
      title: "Right implementation",
      description:
        "Our experts will help you choose the right use cases that will make the most impact for your business and customers.",
      icon: <SettingsIcon sx={{ color: "white" }} />,
    },
    {
      time: "Iterate",
      title: "Scaling higher",
      description:
        "LiaPlus AI's highly scalable platform will enhance your support capabilities by implementing automation for different use cases to help you achieve total digital transformation.",
      icon: <TrendingUpIcon sx={{ color: "white" }} />,
    },
  ];

  return (
    <RoadmapContainer>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        fontWeight="bold"
        color="#AE0EB9"
        sx={{ marginBottom: "30px" }}
      >
        LiaPlus AI Implementation Roadmap
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        <TimelineLine />
        {steps.map((step, index) => {
          const isLeft = index % 2 === 0;
          return (
            <TimelineItem key={index} isLeft={isLeft}>
              <StyledCard isLeft={isLeft}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <IconWrapper>{step.icon}</IconWrapper>
                  <Box>
                    <TimeLabel>
                      <Typography variant="body2" fontWeight="bold">
                        {step.time}
                      </Typography>
                    </TimeLabel>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {step.description}
                    </Typography>
                  </Box>
                </Box>
              </StyledCard>
            </TimelineItem>
          );
        })}
      </Box>
    </RoadmapContainer>
  );
};

export default Roadmap;
