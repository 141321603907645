import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Typography, Paper } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9c27b0", // Purple
    },
    background: {
      default: "#ffffff", // White background
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

const styles = {
  container: {
    minHeight: "100px",
    padding: "2rem",
    background: `url('https://i.postimg.cc/XqqRFHNG/Whats-App-Image-2024-09-27-at-16-59-30-Photoroom-1.png') no-repeat top right`,
    backgroundSize: "60% auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center the content horizontally
  },
  titleContainer: {
    marginBottom: "1.5rem",
    marginTop: "20px",
    width: "85%", // Use full width for responsiveness
    padding: "0 5%", // Add horizontal padding
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "3rem", // Base font size
    lineHeight: "1",
    color: "#000000",
    margin: "10px 0", // Adjust margins for better spacing
    textAlign: "left", // Center text for smaller screens
    "@media (max-width: 600px)": {
      // Mobile devices
      fontSize: "1.5rem", // Smaller font size for mobile
      textAlign: "center",
    },
    "@media (min-width: 600px) and (max-width: 960px)": {
      // Tablets
      fontSize: "2.5rem", // Medium font size for tablets
    },
  },
  highlight: {
    color: "#9c27b0",
    marginLeft: "10px",
  },
  summaryBox: {
    backgroundColor: "#F9ECF9",
    padding: "1.5rem",
    borderRadius: "8px",
    width: "90%", // Use full width on smaller screens
    maxWidth: "1200px", // Max width for larger screens
    margin: "20px auto",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px",
  },
  summaryTitle: {
    fontSize: "1.5rem", // Responsive font size
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#9c27b0",
    textAlign: "left",
  },
  summaryText: {
    fontSize: "1rem", // Use relative font size
    lineHeight: 1.6,
    color: "#000000",
    textAlign: "left",
  },
};

const Section1 = ({ payload }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles.container}>
        <Box sx={styles.titleContainer}>
          <Typography variant="h1" sx={styles.title}>
            AI Voice Agent ROI
          </Typography>
          <Typography variant="h1" sx={styles.title}>
            Report for
            <span style={styles.highlight}>{payload.companyName}</span>
          </Typography>
        </Box>
        <Paper elevation={3} sx={styles.summaryBox}>
          <Typography variant="h2" sx={styles.summaryTitle}>
            ROI Report
          </Typography>
          <Typography sx={styles.summaryText}>
            This report provides a tailored ROI analysis for{" "}
            <strong>{payload.companyName}</strong>, demonstrating the
            cost-saving potential, efficiency gains, and improvements in
            customer service.
          </Typography>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default Section1;
