import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ROICalculatorModal from "../models/ROICalculatorModal";
import Navbar from "../components/Navbar";
import InputField from "../components/InputField";

const Home = () => {
  const [employees, setEmployees] = useState(10);
  const [callDuration, setCallDuration] = useState(3);
  const [callsPerMonth, setCallsPerMonth] = useState(20000);
  const [salary, setSalary] = useState(3500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputData, setInputData] = useState();

  const handleOpenModal = () => {
    const payload = { employees, callDuration, callsPerMonth, salary };
    setInputData(payload);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          bgcolor: "white",
          overflow: "hidden",
          py: { xs: 2, sm: 4 },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: -350,
            left: 0,
            width: { xs: "100%", sm: "400px" },
            height: "700px",
            backgroundImage:
              'url("https://i.postimg.cc/jjwjxy96/Whats-App-Image-2024-09-27-at-14-03-33-Photoroom.png")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            opacity: 0.5,
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <Typography
            variant="h4"
            sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}
          >
            LiaPlus AI ROI Calculator
          </Typography>
          <Box
            sx={{
              bgcolor: "#FFE5FB",
              p: 2,
              borderRadius: 4,
              width: "100%",
              marginBottom: "30px",
            }}
          >
            {/* Using Box for layout instead of Grid */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2, // Space between items
              }}
            >
              <InputField
                label="How many agents do you have?"
                value={employees}
                setValue={setEmployees}
                incrementValue={1}
                decrementValue={1}
              />
              <InputField
                label="What is the average call duration?"
                value={callDuration}
                setValue={setCallDuration}
                suffix="min"
                incrementValue={1}
                decrementValue={1}
              />
              <InputField
                label="How many calls do you process per month?"
                value={callsPerMonth}
                setValue={setCallsPerMonth}
                incrementValue={10000}
                decrementValue={10000}
              />
              <InputField
                label="What is the average monthly cost per agent in USD?"
                value={salary}
                setValue={setSalary}
                suffix="$"
                incrementValue={500}
                decrementValue={500}
              />
            </Box>
          </Box>
          <Button
            onClick={handleOpenModal}
            variant="contained"
            sx={{
              backgroundColor: "white",
              border: "2px solid transparent",
              borderRadius: "5px",
              height: "35px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundClip: "padding-box",
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: "-2.5px",
                left: "-2.5px",
                right: "-2.5px",
                bottom: "-2px",
                background: "linear-gradient(to right, #ff5e78, #5ddcff)",
                borderRadius: "inherit",
                zIndex: -1,
              },
              color: "Black",
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "20px",
              transition: "transform 0.2s ease, background-color 0.2s ease",
              "&:hover": {
                transform: "translateY(-2px)",
                color: "white",
              },
            }}
          >
            Calculate ROI
          </Button>
          <ROICalculatorModal
            open={isModalOpen}
            onClose={handleCloseModal}
            data={inputData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;