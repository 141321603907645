import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px",
  backgroundColor: "#F9ECF9",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  maxWidth: "80%",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const CTAButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "none",
  borderRadius: "50px",
  backgroundColor: "#6B46C1",
  color: "white",
  "&:hover": {
    backgroundColor: "#553C9A",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  marginBottom: theme.spacing(3),
  color: "#AE0EB9",
  fontSize: "2rem",
  textAlign: "left",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  marginBottom: theme.spacing(1),
  color: "#AE0EB9",
  fontSize: "1.25rem",
  textAlign: "left",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const TextBody = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  color: "#000000",
  fontSize: "1rem",
  lineHeight: "1.8",
  textAlign: "left",
  margin: theme.spacing(1, 0),
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  backgroundColor: "#E2E8F0",
}));

const DemoRequestPage = ({payload}) => {
 
  const handleCalendarBooking = () => {
    window.open("https://zcal.co/shaileshjaiswal/lia", "_blank");
  };

  return (
    <Container maxWidth="lg" sx={{ marginBottom: "50px" }}>
      <Box sx={{ my: 6 }}>
        <StyledDivider />
        <Box sx={{ width: "85%", margin: "auto" }}>
          <SectionTitle variant="h4">Conclusion</SectionTitle>
          <TextBody variant="body1">
            After conducting a thorough analysis, it's clear that{" "}
            <strong>{payload.companyName}</strong> stands to gain significant
            long-term value from LiaPlus AI's advanced automation solutions. By
            leveraging this technology, your company will boost operational
            efficiency, reduce costs, and provide exceptional client service.
          </TextBody>
        </Box>
        <StyledDivider />

        <Box sx={{ width: "85%", margin: "auto" }}>
          <SectionTitle variant="h4">Next Steps</SectionTitle>
          <TextBody variant="body1">
            Ready to take the next step? Here's how{" "}
            <strong>{payload.companyName}</strong> can get started with LiaPlus
            AI:
          </TextBody>
        </Box>

        <Box sx={{ width: "85%", margin: "auto", marginTop: "20px" }}>
          <SubTitle variant="h6">
            <strong>Schedule a Consultation:</strong>
          </SubTitle>
          <TextBody variant="body1">
            Book a personalized session to explore how we can tailor LiaPlus AI
            to meet your specific needs and deliver tangible results for your
            business.
          </TextBody>
        </Box>
      </Box>

      {/* Demo Card placed inside Next Steps */}
      <StyledPaper>
        <Box sx={{ flexGrow: 1, mr: { xs: 0, sm: 3 }, mb: { xs: 2, sm: 0 } }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            color="#AE0EB9"
            gutterBottom
          >
            Get on a call to automate 1000s of calls
          </Typography>
          <Typography variant="body2" paragraph color="#000000" sx={{ mb: 2 }}>
            Discover how LiaPlus can become your top-performing AI employee and
            streamline your operations effortlessly.
          </Typography>
        </Box>
        <Box>
          <CTAButton
            variant="contained"
            size="medium"
            onClick={handleCalendarBooking}
          >
            Book a Demo
          </CTAButton>
        </Box>
      </StyledPaper>
      <StyledDivider />

      <Box sx={{ width: "85%", margin: "auto", marginTop: "20px" }}>
        <SectionTitle variant="h5">
          <strong>Contact us:</strong>
        </SectionTitle>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "20.5%",
              justifyContent: "space-between",
            }}
          >
            <p>Phone:</p>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="tel:+919205066063"
            >
              +919205066063
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",

              width: "22%",
              justifyContent: "space-between",
            }}
          >
            <p>Email:</p>
            <a
              style={{
                textDecoration: "none",
                color: "black",
              }}
              href="mailto:hello@liaplus.com"
            >
              hello@liaplus.com
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",

              width: "20.6%",
              justifyContent: "space-between",
            }}
          >
            <p>Website:</p>
            <a
              href="https://liaplus.com/"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              // target="_blank"
              // rel="noreferrer"
            >
              www.liaplus.com
            </a>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DemoRequestPage;
