import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import "./SingleChart.css";

// Styled components for the table and container
const StyledTable = styled(Table)(({ theme }) => ({
  width: "90%",
  marginBottom: "20px",
  borderCollapse: "collapse",
  border: "0.5px solid black",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "12px",
  textAlign: "left",
  fontWeight: "400",
  fontSize: "14px",
  border: "0.5px solid black",
  color: "#333",
}));

const HeaderCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: "#ee80f5",
  color: "#333",
  fontWeight: "bold",
  fontSize: "16px",
}));

const Container = styled("div")({
  backgroundColor: "#fff",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "90%",
  margin: "0 auto",
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "bold",
  margin: "15px 0",
  color: "black",
  textAlign: "center",
  "@media (max-width: 600px)": {
    fontSize: "18px",
  },
  "@media (min-width: 600px) and (max-width: 960px)": {
    fontSize: "20px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9ECF9",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
}));

const TotalSavingsMetric = styled("div")({
  textAlign: "center",
  marginTop: "50px",
  marginBottom: "20px",
  fontFamily: "Poppins, sans-serif",
  fontSize: "32px",
  fontWeight: "bold",
  "@media (max-width: 600px)": {
    fontSize: "24px",
  },
});

const SavingsValue = styled("span")({
  color: "#AE0EB9",
  fontWeight: "bolder",
});

// Updated SingleBarChart component
const SingleBarChart = ({ savings, graphContainerStyle }) => {
  const formatYAxis = (value) => `$${value.toLocaleString()}`;

  const customTooltipStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    padding: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={customTooltipStyle}>
          <p style={{ margin: 0, fontWeight: "bold", color: "#333" }}>
            {label}
          </p>
          <p style={{ margin: "5px 0 0", color: "#ee80f5" }}>
            {`$${payload[0].value.toLocaleString()}`}
          </p>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#333"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="12"
        >
          ${value.toLocaleString()}
        </text>
      </g>
    );
  };

  return (
    <div className="chart-container" style={graphContainerStyle}>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontFamily: "Arial, sans-serif",
          fontSize: "20px",
          color: "#333",
        }}
      >
        Savings Over Time
      </h2>
      <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
        <ResponsiveContainer>
          <BarChart
            data={savings}
            margin={{ top: 20, right: 30, left: 50, bottom: 60 }}
          >
            <defs>
              <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#ee80f5" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#ee80f5" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <XAxis
              dataKey="year"
              tick={{
                fill: "#666",
                fontFamily: "Arial, sans-serif",
                fontSize: "12px",
              }}
              axisLine={{ stroke: "#e0e0e0" }}
              tickMargin={15}
              label={{
                value: "Time Period",
                position: "bottom",
                offset: 40,
                fill: "#333",
                fontSize: 14,
              }}
            />
            <YAxis
              tickFormatter={formatYAxis}
              tick={{
                fill: "#666",
                fontFamily: "Arial, sans-serif",
                fontSize: "12px",
              }}
              axisLine={{ stroke: "#e0e0e0" }}
              tickMargin={5}
              label={{
                value: "Savings (USD)",
                angle: -90,
                position: "insideLeft",
                offset: -40,
                fill: "#333",
                fontSize: 14,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              align="center"
              verticalAlign="top"
              height={36}
              iconType="circle"
            />
            <Bar
              dataKey="savings"
              fill="url(#barGradient)"
              radius={[4, 4, 0, 0]}
              name="Annual Savings"
            >
              <LabelList
                dataKey="savings"
                content={renderCustomizedLabel}
                position="top"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// ROIBreakdownTable component
const ROIBreakdownTable = ({ payload, savings }) => {
  const { callsPerMonth, callDuration } = payload;
  const aiCost = 0.08 * callsPerMonth * callDuration * 12; // Annual AI Cost

  const calculateROI = (saving) => ((saving / aiCost) * 100).toFixed(2);

  const formatCurrency = (value) =>
    `$${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;

  return (
    <Container>
      <Title>ROI Breakdown Over Time</Title>
      <StyledTable>
        <TableHead>
          <TableRow>
            <HeaderCell>Year</HeaderCell>
            <HeaderCell>Cumulative Savings</HeaderCell>
            <HeaderCell>ROI %</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {savings.map((saving, index) => (
            <StyledTableRow key={saving.year}>
              <StyledTableCell>{saving.year}</StyledTableCell>
              <StyledTableCell>
                {formatCurrency(saving.savings)}
              </StyledTableCell>
              <StyledTableCell>{calculateROI(saving.savings)}%</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Container>
  );
};

// TotalSavings component
const TotalSavings = ({ savings }) => {
  const totalSavings = savings.reduce((sum, year) => sum + year.savings, 0);
  const formatCurrency = (value) =>
    `$${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;

  return (
    <TotalSavingsMetric>
      Approx. Savings over 3 Years is{" "}
      <SavingsValue>{formatCurrency(totalSavings)}</SavingsValue>
    </TotalSavingsMetric>
  );
};

// Main CostComparisonGraphs component
const CostComparisonGraphs = ({ payload }) => {

  const containerStyle = {
    width: "100%",
    maxWidth: "80%",
    margin: "0 auto",
    padding: "10px",
  };

  const graphContainerStyle = {
    marginBottom: "20px",
  };

  const calculateSavings = () => {
    const { employees, salary, callsPerMonth, callDuration } = payload;
    const humanCost = employees * salary * 12;
    const aiCost = 0.08 * callsPerMonth * callDuration * 12;

    return [
      {
        year: "Year 1",
        savings: 0.5 * humanCost - aiCost,
      },
      {
        year: "Year 2",
        savings: 0.8 * humanCost - aiCost,
      },
      {
        year: "Year 3",
        savings: 0.95 * humanCost - aiCost,
      },
    ];
  };

  const savings = calculateSavings();

  return (
    <div style={containerStyle}>
      <SingleBarChart
        savings={savings}
        graphContainerStyle={graphContainerStyle}
      />
      <TotalSavings savings={savings} />
      <ROIBreakdownTable payload={payload} savings={savings} />
    </div>
  );
};

export default CostComparisonGraphs;
