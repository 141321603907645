import React from "react";
import Section1 from "./Report/Section1";
import DoubleLineGraph from "./Report/DoubleLineGraph";
import Metrics from "./Report/Metrics";
import Roadmap from "./Report/RoadMap";
import BookDemoComponent from "./Report/BookDemo";
import { Box } from "@mui/material";
import DownLoadPdfButton from "../components/DownLoadPdfButton";
import { useLocation } from "react-router-dom";

const Report = () => {
  const location = useLocation();
  let payload;

  if (location.search) {
    // Handle URL with params (admin access from Google Sheets)
    const searchParams = new URLSearchParams(location.search);
    payload = {};
    for (let [key, value] of searchParams.entries()) {
      payload[key] = decodeURIComponent(value);
    }
  } else if (location.state && location.state.payload) {
    // Handle simple /report navigation (normal user access)
    payload = location.state.payload;
  } else {
    // Handle case where no data is available
    payload = null;
  }

  if (!payload) {
    return <div>No data available for this report.</div>;
  }
  return (
    <>
      <DownLoadPdfButton />
      <Box id="report">
        <Section1 payload={payload} />
        <DoubleLineGraph payload={payload} />
        <Metrics payload={payload} />
        <Roadmap />
        <BookDemoComponent payload={payload} />
      </Box>
    </>
  );
};

export default Report;
