import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import CustomScrollbarSelect from "../components/CustomScrollbarSelect";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
          padding: "24px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 5,
            height: "50px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 5,
        },
        icon: {
          right: 8,
        },
      },
    },
  },
});

const url = "https://roi-calculator-backend-beta.vercel.app";

const ROICalculatorModal = ({ open, onClose, data }) => {
  const navigate = useNavigate();
  const [countryOptions, setCountryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    countryCode: "",
    referralSource: "",
  });

  const [nameError, setNameError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);

  // const [allFormData, setAllFormData] = useState();

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countries = data.map((country) => ({
          name: country.name.common,
          code:
            country.idd.root +
            (country.idd.suffixes ? country.idd.suffixes[0] : ""),
        }));
        const sortedCountryIsd = [...countries].sort((a, b) => {
          if (a.name === "United Arab Emirates") return -1;
          if (b.name === "United Arab Emirates") return 1;
          if (a.name === "South Africa") return -1;
          if (b.name === "South Africa") return 1;
          if (a.name === "India") return -1;
          if (b.name === "India") return 1;
          return a.name.localeCompare(b.name);
        });
        const firstThree = sortedCountryIsd.slice(0, 3);
        const remaining = sortedCountryIsd.slice(3);
        const combinedOptions = [
          ...firstThree,
          { divider: true },
          ...remaining,
        ];
        setCountryOptions(combinedOptions);
        setCountryOptions(sortedCountryIsd);
      })
      .catch((error) => console.error("Error fetching ISD codes:", error));
  }, []);

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      validateNameError(value);
      value = validateName(value);
    }
    if (name === "companyName") {
      validateCompanyNameError(value);
      value = validateCompanyName(value);
    }
    if (name === "phone") {
      if (value !== "") {
        validateMobileNo(value);
      }
      value = validateMobileNumber(value);
    }
    if (name === "email") {
      validateEmail(value);
      value = validateEmailLowerCase(value);
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const generateReportUrl = (payload) => {
    const baseUrl = `${window.location.origin}/report`;
    const params = new URLSearchParams();

    Object.entries(payload).forEach(([key, value]) => {
      params.append(key, encodeURIComponent(value));
    });

    return `${baseUrl}?${params.toString()}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      ...formData,
      ...data,
    };

    // setAllFormData(payload);
    const reportUrl = generateReportUrl(payload);
    setLoading(true);
    try {
      const response = await fetch(`${url}/submit-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...payload, reportUrl }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result) {
        onClose();
        navigate("/report", { state: { payload } });
      }
    } catch (error) {
      console.error("Error:", error);
      // setError("An error occurred while submitting the form."); // Handle errors
    } finally {
      setLoading(false);
    }
  };

  const commonFieldProps = {
    InputLabelProps: {
      sx: {
        fontSize: ".9rem",
      },
    },
    sx: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(178,15,184)",
      },
    },
    required: true,
  };

  function validateNameError(name) {
    const nameRegex = /^(?!\s)(?=.{1,60}$)[a-zA-Z\s]+$/;
    setNameError(!nameRegex.test(name));
  }

  function validateCompanyNameError(name) {
    const companyNameRegex = /^(?!\s)(?=.{1,60}$)[a-zA-Z0-9\s]+$/;
    setCompanyNameError(!companyNameRegex.test(name));
  }

  function validateMobileNo(number) {
    const mobileRegex = /^[0-9]{7,15}$/;
    setMobileNoError(!mobileRegex.test(number));
  }

  function validateEmail(email) {
    const emailRegex =
      /^(?=.{1,50}$)[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    setEmailError(!emailRegex.test(email));
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            View your ROI
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ "& > :not(style)": { mt: 2 } }}
          >
            <TextField
              {...commonFieldProps}
              sx={{
                ...commonFieldProps.sx,
                "& .MuiInputLabel-root.Mui-focused": {
                  color: formData.name ? "black" : "rgb(178,15,184)",
                },
              }}
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              error={nameError}
              helperText={
                nameError && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                      gap: "3px",
                      marginLeft: "-10px",
                    }}
                  >
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "15px" }} />
                    only alphabets are allowed.
                  </Box>
                )
              }
            />
            <TextField
              {...commonFieldProps}
              sx={{
                ...commonFieldProps.sx,
                "& .MuiInputLabel-root.Mui-focused": {
                  color: formData.companyName ? "black" : "rgb(178,15,184)",
                },
              }}
              label="Company name"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              error={companyNameError}
              helperText={
                companyNameError && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                      gap: "3px",
                      marginLeft: "-10px",
                    }}
                  >
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "15px" }} />
                    enter correct company name.
                  </Box>
                )
              }
            />
            <TextField
              {...commonFieldProps}
              sx={{
                ...commonFieldProps.sx,
                "& .MuiInputLabel-root.Mui-focused": {
                  color: formData.email ? "black" : "rgb(178,15,184)",
                },
              }}
              label="Business email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              error={emailError}
              helperText={
                emailError && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                      gap: "3px",
                      marginLeft: "-10px",
                    }}
                  >
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "15px" }} />
                    enter correct email address.
                  </Box>
                )
              }
            />
            <Box
              sx={{ display: "flex", alignItems: "flex-start", gap: 2, mt: 2 }}
            >
              <CustomScrollbarSelect
                value={formData.countryCode}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    countryCode: e.target.value,
                  }))
                }
                options={countryOptions.map((country) => ({
                  value: country.code,
                  label: `${country.name} (${country.code})`,
                }))}
                placeholder="Select Country"
              />
              <TextField
                {...commonFieldProps}
                sx={{
                  ...commonFieldProps.sx,
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: formData.phone ? "black" : "rgb(178,15,184)",
                  },
                }}
                label="Phone number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                fullWidth
                error={mobileNoError}
                helperText={
                  mobileNoError && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        gap: "3px",
                        marginLeft: "-10px",
                      }}
                    >
                      <ErrorOutlineOutlinedIcon sx={{ fontSize: "15px" }} />
                      enter correct mobile number between 7-15 digit.
                    </Box>
                  )
                }
              />
            </Box>

            <FormControl
              fullWidth
              sx={{
                mt: 2,
                m: 0,
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "rgb(178,15,184)", // Change border color to red on focus
                  },
              }}
            >
              <Select
                required
                sx={{ height: "50px" }}
                value={formData.referralSource}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    referralSource: e.target.value,
                  }))
                }
                displayEmpty
                renderValue={(value) => {
                  return value ? (
                    value
                  ) : (
                    <span style={{ fontSize: "0.9rem", color: "gray" }}>
                      How did you hear about us?
                    </span>
                  );
                }}
                IconComponent={KeyboardArrowDownIcon}
                name="referralSource"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <MenuItem value="Search Engine">Search Engine</MenuItem>
                <MenuItem value="Linkedin">Linkedin</MenuItem>
                <MenuItem value="Referral">Referral</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              // disabled={loading}
              sx={{
                backgroundColor: "rgb(178,15,184)",
                color: "white",
                width: "70%",
                "&:hover": {
                  bgcolor: "white",
                  color: "rgb(178,15,184)",
                  transform: "scale(1.05)",
                  transition: "all 0.3s ease-in-out",
                  border: "2px solid rgb(178,15,184)",
                },
                fontSize: "16px",
                padding: "12px",
                height: "45px",
                textTransform: "none",
                borderRadius: "10px",
                marginLeft: "15%",
              }}
              disabled={
                nameError ||
                companyNameError ||
                emailError ||
                mobileNoError ||
                loading
              }
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "View Report"
              )}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ROICalculatorModal;

export const validateEmailLowerCase = (value) =>
  value.trimStart().toLowerCase().replace(" ", "");

export const validateMobileNumber = (value) => value.replace(/[^0-9]/g, "");

export const validateName = (value) =>
  value
    .trimStart()
    .replace(/[^a-zA-Z\s]/g, "")
    .replace("  ", " ");

export const validateCompanyName = (value) =>
  value
    .trimStart()
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .replace("  ", " ");
